<template>
  <div class="lesson-bg">
    <div style="margin-top:140px" v-if="tab === 'player'">
      <div class="container pl-3 pr-3 text-right" >
        <span class="btn btn-default" @click="goTabRecord()">
          <!-- <img src="~../assets/image/icon/lesson_score_off.png" />
          <img src="~../assets/image/icon/lesson_score_on.png" v-if="false" /> -->
        </span>
        <span class="btn btn-default" @click="$router.go(-1)">
          <!-- <img src="~../assets/image/icon/close_lesson.png" /> -->
        </span>
      </div>
      <div v-if="lesson" style="height:min-height:50vh">
        <div v-if="lesson.modules">
          <div class="heart m-auto px-3" style="text-align:left;max-width: 720px!important;min-width: 320px!important;">
            <img style="width:25px" v-if="lesson.collection" @click="removeCollection(lesson.id)" class="pointer" src="../assets/red.png" alt="">
            <img style="width:25px" v-else @click="addCollection(lesson)" class="pointer" src="../assets/heart.png" alt="">
          </div>
          <!-- {{time}} -->
          <live-player ref="liveplayer" :moduleEnable="moduleEnable" :liveData="lesson" v-if="lesson.modules.length > 0" />
        </div>
      </div>
    </div>
    <div class="container  mb-3 pt-4 pb-4" style="margin-top:160px" v-if="tab === 'package'">
      <div class="col-12 text-right">
        <span class="btn btn-default" @click="$router.go(-1)">
          <!-- <img src="~../assets/image/icon/close_lesson.pn" /> -->
        </span>
      </div>
      <div class="row ">
        <div class="text-center col-md-6">
          <img class="mb-3" :src=" lesson.cover" style="width: 100%;" />
          <h2>{{ lesson.localName }}</h2>
          <p v-html="forceToJap(lesson.name)">{{ lesson.name }}</p>
          <!-- <p class="introduction" v-if="lesson.introduction">{{ lesson.introduction }}</p> -->
        </div>
        <div class="col">
          <ul class="list-group">
            <li @click="doVideo(lesson)" class="subLession-btn text-left my-2"
              v-for="(lesson, index) in lesson.lessons" :key="index">
              <div class="d-flex justify-content-between">
                <div class="align-items-baseline mr-auto">
                  <!-- <b>{{lesson.localName}}</b> -->
                  <b>{{  9 > index  ? '0' + (index + 1) : (index + 1) }}. {{ lesson.localName }}</b>
                  <span class="ml-3" v-html="forceToJap(lesson.name)"></span>
                  <p class="introduction pl-4" v-if="lesson.introduction">{{ lesson.introduction }}</p>
                </div>
                <div class="d-flex align-items-center mr-3">
                  <!-- <icon-button name="more_info" :alt="開始上課" @click.native="$router.push({ name: 'lesson.player', params: { lessonId: lesson.id } })" /> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import personalApi from '../store/personalApi'
import LivePlayer from 'live-player'
import api from '../store/api'

export default {

  name: 'LessonPlayer',

  components: {
    LivePlayer
  },

  data () {
    return {
      timer: null,
      time: 0,
      tab: 'player',
      lesson: {},
      parentName: null,
      video: null,
      moduleEnable: ['video', 'article', 'ebook', 'text', 'reading', 'detail', 'practice', 'vocabulary']
    }
  },

  computed: {
    topic () {
      return this.$route.params.topic
    },
    infoId () {
      return this.$route.params.infoId
    },
    lessonId () {
      return this.$route.params.lessonId
    }
  },
  watch: {
    // 'video.state': {
    //   handler: function (val) {
    //     // console.log(val)
    //     this.counter(val)
    //   },
    //   deep: true
    // }
  },
  created () {
    this.getLessonData()
  },
  async mounted () {
    if (this.topic === 'mag') {
      await this.getMag()
    } else {
      await this.getCity()
    }
    this.counter('playing')
    this.$nextTick(() => {
      if (this.$refs) {
        this.$watch(
          '$refs.liveplayer.video',
          (newVal, oldVal) => {
            this.video = newVal
          }
        )
      }
    })

    // this.$watch('$route.params.lessonId', (val, newVal) => {
    //   clearInterval(this.recordTimer)
    //   this.recordTimer = null
    //   this.tab = 'player'
    //   this.getLessonData()
    //   this.startRecord()
    // })
    const subTile = [
      {
        name: '首頁',
        routeName: 'Home'
      },
      {
        name: this.topic === 'mag' ? '雜誌館' : '遊台灣',
        routeName: 'Topic',
        params: this.$route.params
      },
      {
        name: this.parentName,
        routeName: this.checkRate(this.infoId) && this.topic === 'mag' ? 'Theme-info' : 'Info',
        params: this.$route.params
      }
    ]
    this.$store.commit('setSubTitle', subTile)
  },
  async beforeDestroy () {
    if (this.tab === 'player') {
      if (this.lesson.id) {
        const fileData = new window.FormData()
        fileData.append('seconds', this.time)
        await personalApi.post(`records/lesson/${this.lesson.id}/time`, fileData)
        clearInterval(this.timer)
        this.recordTimer = null
      } else {
        clearInterval(this.timer)
        this.recordTimer = null
      }
    }
  },
  video (val) {
    this.status = val
  },
  methods: {
    async getLessonData () {
      const lessonId = this.$route.params.lessonId
      await this.getLesson(lessonId)

      if (this.lesson.lessonType === 'Package') {
        this.tab = 'package'
      }
    },
    async getLesson () {
      const { data } = await api.get(`/Lesson/${this.lessonId}`)
      if (data.status === 'OK') {
        for (const i in data.data.modules) {
          if (data.data.modules[i].type === 'Youtube') {
            this.moduleEnable.push('youtube')
          }
        }
        if (data.data.lessons) {
          this.lesson = data.data
        } else {
          if (this.$store.state.auth.authorized) {
            this.checkCollection(data.data)
          } else {
            this.lesson = data.data
          }
        }
      }
    },
    async goTabRecord () {
      const lessonId = this.$route.params.lessonId
      const { data } = await api.get('/Lesson/' + lessonId + '/Record')

      if (data.status === 'OK') {
        this.tab = 'record'
        this.record = data.data
      }
    },
    doVideo (el) {
      this.$router.push({ name: 'Lesson-player', params: { topic: this.topic, infoId: this.infoId, lessonId: el.id } })
    },
    startRecord () {
      if (this.$route.params.lessonId) {
        api.post('Lesson/' + this.$route.params.lessonId + '/Record/add')
      }

      this.recordTimer = setInterval(() => {
        api.post('Lesson/' + this.$route.params.lessonId + '/Record/update')
      }, 5000)
    },
    checkRate (nubmer) {
      const re = /^[0-9]+.?[0-9]*/
      if (!re.test(nubmer)) {
        return false
      }
      return true
    },
    async getMag () {
      if (!this.checkRate(this.infoId)) {
        const { data } = await api.get(`/Mag/${this.infoId}`)
        if (data.status === 'OK') {
          this.parentName = data.data.name
        }
      } else {
        const params = {
          parentId: '',
          magId: this.infoId
        }
        const { data } = await api.get('/Mag/Category/', { params })
        if (data.status === 'OK') {
          // console.log(data)
          this.parentName = data.data[this.infoId - 1].name
        }
      }
    },
    async getCity () {
      const { data } = await api.get(`/TravelTaiwan/County/${this.infoId}`)
      if (data.status === 'OK') {
        // console.log(data)
        this.parentName = data.data.name
      }
    },
    async addCollection (el) {
      if (this.$store.state.auth.authorized) {
        const json = {
          name: 'Lesson-player',
          localName: el.localName,
          enName: el.name,
          params: {
            infoId: this.$route.params.infoId,
            topic: this.$route.params.topic,
            lessonId: el.id
          }
        }
        const fileData = new window.FormData()
        fileData.append('json', JSON.stringify(json))
        fileData.append('view_id', el.id)
        await personalApi.post('/collections/live_course', fileData)
        this.getLesson()
        // if (this.topic === 'mag') {
        //   // this.getMagLessons()
        // } else {
        //   // this.getTaiwanLessons()
        // }
      } else {
        this.$swal({
          title: '請登入個人帳號',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    },
    async checkCollection (el) {
      try {
        const { data } = await personalApi.get(`collections/live_course/check?ids=${el.id}`)
        // console.log(el)
        if (el.id === data.data[0]) {
          el.collection = true
        } else {
          el.collection = false
        }
      } catch (error) {
        console.error(error.response.data)
      }
      this.lesson = el
    },
    async removeCollection (id) {
      try {
        await personalApi.delete(`/collections/live_course/${id}`)
        this.getLesson()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    countup () {
      this.time++
    },
    async counter (el) {
      // console.log(el)
      if (el === 'playing') {
        // this.time++
        this.timer = setInterval(this.countup, 1000)
      } else {
        const fileData = new window.FormData()
        fileData.append('seconds', this.time)
        await personalApi.post(`records/lesson/${this.lessonId}/time`, fileData)
        this.time = 0
        clearInterval(this.timer)
      }
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/LivePlayer.css';
@import "../assets/styles/custom.scss";
  .lesson-bg {
    min-height: 750px;
    height: 100%;
  }
  .subLession-btn {
    cursor: pointer;
    border: 1px solid rgb(167, 167, 167);
    color: rgb(6, 114, 238);
    background-color: #fff;
    border-radius: .8rem;
    padding: 1rem;
    list-style: none;
  }
  .subLession-btn:hover {
    box-shadow:3px 2px 2px 1px rgb(136, 136, 136);
    transform: scale(1.05);
  }
</style>
