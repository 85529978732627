<template>
  <div class="footer">
    版權所有 2020 LiveABC互動英語教學集團 | Hebron Soft Limited All Rights Reserved
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .footer {
    width: 100%;
    padding: 15px;
    line-height: 2rem;
    background-color:rgb(50, 79, 248);
    color: #fff;
  }
</style>
