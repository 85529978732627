import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Info from '../views/Info.vue'
import Topic from '../views/Topic.vue'
import LessonPlayer from '../views/LessonPlayer.vue'
import Personal from '../views/Personal.vue'
import Record from '../components/Record.vue'
import Collections from '../components/Collections.vue'
// import Daily from '../views/Daily.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,
    children: [
      { path: '', name: 'Record', component: Record },
      { path: 'collections', name: 'Collections', component: Collections }
    ]
  },
  {
    path: '/lesson/:topic',
    name: 'Topic',
    component: Topic
  },
  {
    path: '/lesson/:topic/:infoId?',
    name: 'Info',
    component: Info
  },
  {
    path: '/lesson/:topic/theme/:infoId?',
    name: 'Theme-info',
    component: Info
  },
  {
    path: '/lesson/:topic/course/:infoId/:lessonId?',
    name: 'Lesson-player',
    component: LessonPlayer
  },
  { path: '*', redirect: { name: 'Home' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
