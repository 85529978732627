<template>
  <div>
    <pop-up v-if="toggle" :inputStatus="'update'" v-on:closePopUp="popUpToggle">
    </pop-up>
    <div class="container-fluid" style="padding:0 10%; margin-top:140px">
      <div class="row topic-selection d-flex justify-content-between">
        <div class="pt-4 pb-3">
          <div class=" m-2" v-if="$route.name === 'Collections'">
            <img style="width:40%" @click="$router.push({ name: 'Record'})" class="pointer" src="../assets/img/元件/btn_my_record_off.png" alt="taiwan_on">
            <img style="width:40%" class="pointer mx-2" src="../assets/img/元件/btn_my_collect_on.png" alt="taiwan_off">
          </div>
          <div class=" m-2" v-if="$route.name === 'Record'" >
            <img style="width:40%" class="pointer" src="../assets/img/元件/btn_my_record_on.png" alt="taiwan_on">
            <img style="width:40%"  @click="$router.push({ name: 'Collections'})" class="pointer mx-2" src="../assets/img/元件/btn_my_collect_off.png" alt="magazine_on">
          </div>
        </div>
        <div class="d-flex">
          <h5 class="mt-5 mx-2 function-btn pointer" @click="toggle = true">修改密碼</h5>
          <h5 class="mt-5 function-btn pointer" @click="logout">我要登出</h5>
        </div>
        <div class="personal-main">
          <router-view style="min-height:100vh"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import PopUp from '@/components/Pop-up.vue'
import { mapMutations } from 'vuex'
export default {
  name: 'Personal',
  components: {
    PopUp
  },
  data () {
    return {
      toggle: false
    }
  },
  mounted () {
    const subTitle = [
      {
        name: '首頁',
        routeName: 'Home'
      },
      {
        name: '個人服務',
        routeName: 'Record'
      }
    ]
    this.$store.commit('setSubTitle', subTitle)
    if (!this.$store.state.auth.authorized) {
      this.logout()
    }
  },
  methods: {
    ...mapMutations({
      dologout: 'logout'
    }),
    logout () {
      this.$router.push({ name: 'Home' })
      this.dologout()
    },
    popUpToggle (val) {
      this.toggle = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .function-btn {
    font-weight: bold;
    color: #324ff8;
  }
  .personal-main {
    background-color: rgb(218, 218, 218);
    width: 100vw;
    min-height: 110vh;
    margin-bottom: 20px;
    padding: 20px;
    text-align: left;
    .personal-box {
      width: 100%;
      margin: 5px auto;
      padding: 20px 0 20px 0 ;
      border-bottom: 1px solid #000;
      ul {
        list-style: none;
        li{
          font-weight: bolder;
          margin: 0 10px 0 0;
          p{
            padding: 0;
          }
        }
      }
    }
  }
</style>
