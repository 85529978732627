<template>
  <div>
    <div class="record">
      <div>
        <div class="personal-box d-flex justify-content-between">
          <div>
            <img style="width:35px" src="../assets/img/元件/icon_black_clock.png" alt="">
            <span class="mx-2">觀看使用時間:</span> <b>{{formatSeconds(totalTime)}}</b>
          </div>
          <div class="d-flex">
            <div class="form-check">
              <input class="form-check-input" v-model="sort" value="update" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <label class="form-check-label" for="flexRadioDefault1">
                最後觀看日期
              </label>
            </div>
            <div class="form-check mx-2">
              <input class="form-check-input" v-model="sort" value="time" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
              <label class="form-check-label" for="flexRadioDefault2">
                閱讀時數
              </label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- <h6>以下是您的收藏課程</h6> -->
        <div v-for="(item, index) in records" class="my-5" :key="index">
          <div class="d-flex align-items-stretch">
            <div class="m-auto pr-3">
              <!-- 2021/12/03 -->
              {{moment(item.updated_at).format('YYYY/MM/DD')}}
            </div>
            <div class="flex-grow-1" v-if="item.data">
              <b><p v-html="forceToJap(item.data.Name)"></p></b>
              <!-- <p class="m-0"><b>{{item.data.Name}}</b></p> -->
              <p class="m-0">{{item.data.NameCht}}</p>
            </div>
            <div class="my-auto mx-3">
              <!-- {{item.seconds}} -->
              {{formatSeconds(item.seconds)}}
            </div>
            <div class="m-auto">
              <button type="button" @click="goPage(item)" class="btn btn-primary">前往課程</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination v-if="records.length>0" :current="pageIndex" :total="pageCount" v-on:page="toPage" />
  </div>
</template>
<script>
// @ is an alias to /src
// import PopUp from '@/components/Pop-up.vue'
import pagination from '../components/Pagination'
import api from '../store/api'
import personalApi from '../store/personalApi'
export default {
  name: 'Record',
  components: {
    pagination
  },
  data () {
    return {
      records: [],
      sort: 'update',
      totalTime: 0,
      pageIndex: 0,
      pageCount: 0
    }
  },
  watch: {
    sort () {
      this.getRecord()
    },
    pageIndex () {
      this.getRecord()
    }
  },
  async mounted () {
    this.pageIndex = 1
    await this.getTotalTime()
    const subTitle = [
      {
        name: '首頁',
        routeName: 'Home'
      },
      {
        name: '個人服務',
        routeName: 'Personal'
      }
    ]
    await this.getRecord()
    this.$store.commit('setSubTitle', subTitle)
  },
  methods: {
    async getRecord () {
      const { data } = await personalApi.get(`records/livecourse?page=${this.pageIndex}&pageSize=10&sort=${this.sort}`)
      this.pageCount = data.pageCount
      this.records = data.data
    },
    async getTotalTime () {
      const { data } = await personalApi.get('records/livecourse/time')
      this.totalTime = data.data.total_time
    },
    formatSeconds (millisec) {
      var date = new Date(null)
      date.setSeconds(millisec) // specify value for SECONDS here
      var result = date.toISOString().substr(11, 8)

      return result
    },
    async goPage (el) {
      const { data } = await api.get(`/Lesson/${el.view_id}/meta`)
      this.$router.push({ name: 'Lesson-player', params: { topic: data.data.sourceType, infoId: data.data.magName || '65', lessonId: el.view_id } })
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getRecord()
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .record {
    min-height: 100vh;
  }
  .personal-main {
    background-color: rgb(218, 218, 218);
    width: 100vw;
    height: 80vh;
    padding: 20px;
    text-align: left;
    .personal-box {
      width: 100%;
      margin: 5px auto;
      padding: 20px 0 20px 0 ;
      border-bottom: 1px solid #000;
      ul {
        list-style: none;
        li{
          font-weight: bolder;
          margin: 0 10px 0 0;
          p{
            padding: 0;
          }
        }
      }
    }
  }
</style>
