import Vue from 'vue'
import Vuex from 'vuex'
import personalApi from './personalApi'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    subTitle: [],
    isLoading: false,
    errorMessage: '',
    auth: {
      name: '',
      authorized: false,
      user: '',
      verify: false
    }
  },
  mutations: {
    isLoading (state, value) {
      state.isLoading = value
    },
    setSubTitle (state, data) {
      // console.log(data)
      state.subTitle = data
    },
    login (state, value) {
      // state.auth.authorized = true
      state.auth.user = value.token
    },
    logout (state, value) {
      state.auth.authorized = false
      state.auth.user = ''
      state.auth.name = ''
      state.auth.verify = false
    },
    setPersonalInfo (state, value) {
      state.auth.name = value.name
      if (value.email_verified_at !== null) {
        state.auth.verify = true
        state.auth.authorized = true
      } else {
        state.auth.verify = false
      }
    },
    setErrorMessage (state, message) {
      state.errorMessage = message
    }
  },
  actions: {
    login: async ({ commit, dispatch }, user) => {
      const fileData = new window.FormData()
      fileData.append('email', user.email)
      fileData.append('password', user.password)
      fileData.append('device_name', 'web')
      const { data } = await personalApi.post('/login', fileData)
      commit('login', data)
      // console.log(data)
      return data
    },
    logout: async ({ commit }) => {
      const { data } = await personalApi.post('/logout')
      commit('logout', data)
    },
    setPersonalInfo: async ({ commit }) => {
      const { data } = await personalApi.get('/me')
      commit('setPersonalInfo', data)
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    errorMessage: state => state.errorMessage,
    authorized: state => state.auth.authorized,
    name: state => state.auth.name,
    verify: state => state.auth.verify
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        key: 'library',
        paths: [
          'auth'
          // 'superAdmin'
        ]
      }
    )
  ]
})
