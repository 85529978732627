<template>
  <div class="background">
    <pop-up v-if="toggle" v-on:closePopUp="popUpToggle" />
    <nav class="navbar navbar-light bg-light px-5">
      <img src="../assets/img/元件/logo_liveabc_only_S_size.png" alt="">
      <div class="d-flex" style=" position: relative;">
        <span v-if="!this.$store.state.auth.authorized" class="pointer" @click="openPersonal">
          登入/註冊
        </span>
        <span v-else  @click="openPersonal" class="pointer">
          你好，{{name}}
        </span>
        <span>
        </span>
        <div class="info pointer" @mouseover="infoActive = true" @mouseleave="infoActive = false">
          i
        </div>
        <div class="info-content" :class="{hide:!infoActive}">
          請登入個人帳號後，可使用網站中的個人使用紀錄功能及收藏功能。
        </div>
      </div>
    </nav>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img class="book" src="../assets/img/元件/pic_book.png" alt="">
        </div>
        <div class="title-boxs col-7">
          <img src="../assets/img/元件/pic_company_logo_S.png" alt="">
          <br>
          <div style="color:#fff;float:right;font-size:1.2rem">
            總單元數: {{lesson_counts}}
          </div>
          <!-- <img src="../assets/img/元件/pic_library_logo.png" alt=""> -->
        </div>
      </div>
      <div style="margin-top:-80px" class="row justify-content-end title">
        <div class="col-md-5">
          <div class="btn-topic" @click="goPage('mag')">
            <!-- <img src="../assets/img/元件/btn_enter_topic.png" alt=""> -->
            <img class="home-btn" style="min-height:5rem; min-width:300px" src="../assets/img/元件/btn_home_magazine.png" alt="">
            <!-- <p>雜誌主題館</p> -->
          </div>
          <div class="btn-topic" @click="goPage('taiwan')">
            <!-- <img  style="min-height:5rem; min-width:300px" src="../assets/img/元件/btn_enter_topic.png" alt=""> -->
              <img style="min-height:5rem; min-width:300px" src="../assets/img/元件/btn_home_taiwan.png" alt="">
            <!-- <p>遊臺灣學英文</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="home-footer">
      版權所有 2020 LiveABC互動英語教學集團 | Hebron Soft Limited All Rights Reserved
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import api from '../store/api'
import { mapGetters } from 'vuex'
import PopUp from '@/components/Pop-up.vue'
import personalApi from '../store/personalApi'
export default {
  name: 'Home',
  components: {
    PopUp
  },
  data () {
    return {
      infoActive: false,
      toggle: false,
      lesson_counts: 0
    }
  },
  computed: {
    ...mapGetters([
      'authorized',
      'name'
    ])
  },
  mounted () {
    this.getLesonCount()
  },
  methods: {
    goPage (topic) {
      // const subTile = [
      //   {
      //     name: '首頁',
      //     path: 'Home'
      //   }
      // ]
      // this.$store.commit('setSubTitle', subTile)
      this.recordsActions(topic)
      this.$router.push({ name: 'Topic', params: { topic: topic } })
    },
    openPersonal () {
      if (this.authorized) {
        this.$router.push({ name: 'Record' })
      } else {
        this.toggle = true
      }
    },
    async recordsActions (name) {
      const fileData = new window.FormData()
      fileData.append('name', name)
      fileData.append('type', 'view_page')
      await personalApi.post('/records/actions', fileData)
    },
    openInfo () {
      this.infoActive = !this.infoActive
    },
    popUpToggle (val) {
      this.toggle = val
    },
    async getLesonCount () {
      const { data } = await api.get('/Lessons/count')
      if (data.status === 'OK') {
        this.lesson_counts = data.data.count
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .background {
    min-height: 100vh;
    max-height: 200vh;
    background-size: cover;
    background-image: url('../assets/img/元件/pic_bg.jpg');
    .title-boxs {
      img {
        padding: 20px 0;
        min-width: 100%;
        max-width: 100%;
      }
      text-align: left;
    }
    .btn-topic {
      cursor: pointer;
      position: relative;
      text-align: left;
      margin-bottom: 2rem;
      line-height: 4rem;
      padding: 0 40px;
      font-size: 1.5rem;
      font-weight: bold;
      p {
        padding: 0;
        position: absolute;
        top: 0;
        left: 50px;
      }
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .hide {
    display: none;
  }
  .info {
    border-radius: 50px;
    background-color: #000;
    margin: auto 5px;
    width: 16px;
    height: 16px;
    color: #fff;
    font-size: .8rem;
  }
  .info-content {
    padding: 10px;
    right: 0px;
    top: 50px;
    position: absolute;
    width: 200px;
    height: 100px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 500;
  }
  .home-footer {
    font-size: 1rem;
    width: 100vw;
    z-index: 1;
    color: rgba(255, 255, 255);
    position: fixed;
    bottom: 0;
  }
  @media screen and (min-width: 1200px){
    .container {
      width: 60%;
    }
  }
  @media screen and (max-width: 480px){
    .title-boxs {
      min-width: 300px;
    }
    .book {
      width: 50% !important;
    }
    .title {
      margin-top: 50px !important;
    }
  }
</style>
