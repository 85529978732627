<template>
  <div style="min-height: calc(100vh - 5vh);">
    <div class="container-fluid" style="padding: 0 10%; margin-top:100px">
      <div class="row topic-selection">
        <div class="pt-5">
          <div>
            <div v-if="this.$route.params.topic === 'mag'">
              <img style="width:150px" class="pointer m-2" src="../assets/img/元件/btn_magazine_on.png" alt="magazine_on">
              <img style="width:150px" class="pointer m-2" @click="goPage('taiwan')" src="../assets/img/元件/btn_taiwan_off.png" alt="taiwan_off">
              <img style="width:150px" class="pointer m-2" @click="goPage('daily')" src="../assets/img/元件/btn_top_daily_off.png" alt="daily_off">
            </div>
            <div v-if="this.$route.params.topic === 'taiwan'">
              <img style="width:150px" class="pointer m-2" @click="goPage('mag')" src="../assets/img/元件/btn_magazine_off.png" alt="magazine_off">
              <img style="width:150px" class="pointer m-2" src="../assets/img/元件/btn_taiwan_on.png" alt="taiwan_on">
              <img style="width:150px" class="pointer m-2" @click="goPage('daily')" src="../assets/img/元件/btn_top_daily_off.png" alt="daily_off">
            </div>
            <div v-if="this.$route.params.topic === 'daily'">
              <img style="width:150px" class="pointer m-2" @click="goPage('mag')" src="../assets/img/元件/btn_magazine_off.png" alt="magazine_off">
              <img style="width:150px" class="pointer m-2" @click="goPage('taiwan')" src="../assets/img/元件/btn_taiwan_off.png" alt="taiwan_off">
              <img style="width:150px" class="pointer m-2" src="../assets/img/元件/btn_top_daily_on.png" alt="daily_on">
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.$route.params.topic==='mag'" class="row justify-content-center p- my-4" >
        <div class="col-12 topic-title" >
          <h2>
            雜誌館
          </h2>
          <a style="text-decoration:none; cursor: default;" href="javascript:void(0)">請點選下方分類封面進入</a>
          <div style="text-align:right">
            單元數: {{lesson_counts}}
          </div>
        </div>
        <div v-for="(item, key) in mags" :key="key" class="col-md-2 my-2 topic p-3" style="background:#E1F7FC;" >
          <div class="picture pointer">
            <img @click="goInfo(item)" :src="item.cover" alt="">
          </div>
        </div>
      </div>
      <div v-if="this.$route.params.topic==='taiwan'" class="row justify-content-center p- my-4" >
        <div class="col-12 topic-title" >
          <h2>
            遊台灣
          </h2>
          <a style="text-decoration:none; cursor: default;" href="javascript:void(0)">請點選下方分類封面進入</a>
          <div style="text-align:right">
            單元數: {{lesson_counts}}
          </div>
        </div>
        <div v-for="(item, key) in citys" :key="key" class="col-md-2 my-2 topic p-3" style="background:#E1F7FC;" >
          <div class="picture pointer">
            <img @click="goInfo(item)" :src="item.cover" alt="">
          </div>
        </div>
      </div>
      <!-- <div v-if="this.$route.params.topic==='taiwan'" class="row justify-content-center taiwan">
        <div class="col-12 topic-title">
          <h2>
            遊台灣學英文
          </h2>
          <a style="text-decoration:none; cursor: default;" href="javascript:void(0)">請點選下方分類封面進入</a>
          <carousel class="tw p-3" v-if="this.$route.params.topic==='taiwan'" :perPageCustom="[[320,4],[1200,6]]"  style="background:#E1F7FC; border-radius: 20px">
            <slide class="p-2" v-for="(item, key) in citys" :key="key">
              <img @click="goInfo(item)" class="pointer" :src="item.cover" :alt="item.name">
            </slide>
          </carousel>
        </div>
      </div> -->
      <div v-if="this.$route.params.topic==='taiwan'">
        <div v-for="(item, key) in citys" :key="key" class="col-md-2 my-2 topic rwd-tw pointer">
          <div class="picture">
            <img @click="goInfo(item)" :src="item.cover" alt="">
          </div>
        </div>
      </div>
      <div v-if="this.$route.params.topic === 'mag'" class="row justify-content-center p-2" style="margin-bottom:20px">
        <div class="col-12 topic-title">
          <h2>
            主題館
          </h2>
          <a style="text-decoration:none; cursor: default;" href="javascript:void(0)">
            請點選下方分類封面進入
          </a>
        </div>
        <div v-for="(item,key) in topics" :key="key"  class="col-md-3 p-3 topic" style="background:#FFF1E8;">
          <div class="picture">
            <img class="pointer" :src="item.horizontalCover" :alt="key" @click="goThemeInfo(item)">
          </div>
          <!-- <h5>
            {{item.name}}
          </h5> -->
        </div>
      </div>
      <div v-if="this.$route.params.topic === 'taiwan'" class="row justify-content-center p-2" style="margin-bottom:20px">
        <div class="col-12 topic-title">
          <h2>
            主題館
          </h2>
          <a style="text-decoration:none; cursor: default;" href="javascript:void(0)">
            請點選下方分類封面進入
          </a>
        </div>
        <div v-for="(item,key) in topics" :key="key"  class="col-md-3 p-3 topic" style="background:#FFF1E8;">
          <div class="picture">
            <img class="pointer" :src="item.horizontalCover" :alt="key" @click="goThemeInfo(item)">
          </div>
          <!-- <h5>
            {{item.name}}
          </h5> -->
        </div>
      </div>
      <div v-if="this.$route.params.topic==='daily'">
        <div class="search pb-3 d-flex justify-content-between mt-3">
          <h2>每日一句</h2>
          <div>
            <input type="text" v-on:keyup.enter="doSearch" v-model="search" placeholder="關鍵字搜尋"><button @click="doSearch" type="button" class="btn btn-primary ml-3">搜尋</button>
          </div>
        </div>
        <div class="daily-lists">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width:10%">日期</th>
                <th style="width:90%; text-align: left;" colspan="3">學習內容</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in Sentences" :key="index">
                <th style="vertical-align:inherit">{{moment(item.publish_at).format('YYYY/MM/DD') }}</th>
                <td class="d-flex align-items-center ">
                  <div v-if="item.audio_url" class="mr-2">
                    <font-awesome-icon size="lg" @click="audioPasue(item)" class="pointer"  v-if="isPlay === item.id" :icon="['far', 'pause-circle']" />
                    <font-awesome-icon size="lg" @click="playAudio(item)" class="pointer" v-else :icon="['far', 'play-circle']" />
                  </div>
                  <div class="mr-4" v-else>
                  </div>
                  <div>
                    <p class="m-0"> {{item.local}}</p>
                    <p class="m-0">{{item.english}}</p>
                  </div>
                </td>
                <td class="p-0" style="vertical-align: inherit; text-align:center">
                  <div class="heart">
                    <img class="pointer" v-if="item.collection" @click="removeCollections(item.id)" src="../assets/red.png" alt="">
                    <img class="pointer" v-else @click="addCollections(item)" src="../assets/heart.png" alt="">
                  </div>
                </td>
                <div v-if="item.audio_url">
                  <audio :id="`daily-sentences${item.id}`">
                    <source :src="item.audio_url" type="audio/mp3">
                  </audio>
                </div>
              </tr>
            </tbody>
          </table>
          <pagination class="py-3" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import personalApi from '../store/personalApi'
import Pagination from '../components/Pagination'
import axios from 'axios'
import api from '../store/api'
export default {
  name: 'Topic',
  components: {
    Pagination
  },
  data () {
    return {
      topics: [],
      mags: [],
      citys: [],
      Sentences: [],
      isPlay: null,
      search: '',
      lesson_counts: 0,
      pageIndex: 1,
      pageCount: 0
    }
  },
  updated () {
    let type = ''
    switch (this.topic) {
      case 'mag':
        type = '雜誌館'
        break
      case 'taiwan':
        type = '遊台灣'
        break
      case 'daily':
        type = '每日一句'
        break
    }
    const subTile = [
      {
        name: '首頁',
        routeName: 'Home'
      },
      {
        name: type,
        routeName: this.$route.name,
        params: this.$route.params
      }
    ]
    this.$store.commit('setSubTitle', subTile)
  },
  watch: {
    topic () {
      if (this.topic === 'mag') {
        this.getMag()
      } else {
        this.getCity()
      }
    }
  },
  mounted () {
    const subTile = [
      {
        name: '首頁',
        routeName: 'Home'
      },
      {
        name: this.topic === 'mag' ? '雜誌館' : '遊台灣',
        routeName: this.$route.name,
        params: this.$route.params
      }
    ]
    this.getDailySentences()
    this.$store.commit('setSubTitle', subTile)
    if (this.topic === 'mag') {
      this.getMag()
    } else {
      this.getCity()
    }
    this.getCate()
  },
  computed: {
    topic () {
      return this.$route.params.topic
    }
  },
  methods: {
    async getMag () {
      const { data } = await api.get('/Mag')
      if (data.status === 'OK') {
        this.mags = data.data
        this.lesson_counts = data.lesson_counts
      }
    },
    async getCity () {
      const { data } = await api.get('/TravelTaiwan/County')
      if (data.status === 'OK') {
        this.citys = data.data
        this.lesson_counts = data.lesson_counts
      }
    },
    async getCate () {
      if (this.topic === 'mag') {
        const { data } = await api.get('/Mag/Category')
        if (data.status === 'OK') {
          this.topics = data.data
        }
      } else {
        const { data } = await api.get('/TravelTaiwan/Category')
        if (data.status === 'OK') {
          this.topics = data.data
        }
      }
    },
    async getDailySentences (el) {
      const params = {
        query: el
      }
      const { data } = await axios.get(`https://api.liveabc.com/v1/daily-sentences?page=${this.pageIndex}`, { params })
      // this.Sentences = data.data

      if (this.$store.state.auth.authorized) {
        this.checkCollections(data.data)
      } else {
        this.Sentences = data.data
      }
      this.pageCount = data.last_page
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.pauseAll()
      this.getDailySentences(this.search)
    },
    goPage (topic) {
      this.titleRecordsActions(topic)
      this.$router.push({ name: 'Topic', params: { topic: topic } })
    },
    async recordsActions (id, name) {
      const fileData = new window.FormData()
      fileData.append('id', id)
      fileData.append('type', 'view_category')
      fileData.append('name', name)
      await personalApi.post('/records/actions', fileData)
    },
    async titleRecordsActions (name) {
      const fileData = new window.FormData()
      fileData.append('name', name)
      fileData.append('type', 'view_page')
      await personalApi.post('/records/actions', fileData)
    },
    goInfo (item) {
      this.recordsActions(item.id, item.name)
      console.log(item)
      this.$router.push({ name: 'Info', params: { topic: this.topic, infoId: item.id } })
    },
    goThemeInfo (item) {
      this.recordsActions(item.id, item.name)
      this.$router.push({ name: 'Theme-info', params: { topic: this.topic, infoId: item.id } })
    },
    playAudio (el) {
      this.isPlay = el.id
      this.playSentences(el.id)
    },
    playSentences (id) {
      const audio = document.getElementById(`daily-sentences${id}`)
      const audioPromise = document.getElementById(`daily-sentences${id}`).play()
      this.pauseAll()
      if (audioPromise !== undefined) {
        audioPromise.then(_ => {
          audio.play()
        })
      }
      audio.load()
      audio.play()
    },
    audioPasue (el) {
      this.isPlay = ''
      const audio = document.getElementById(`daily-sentences${el.id}`)
      audio.pause()
    },
    pauseAll () {
      const audios = document.getElementsByTagName('audio')
      const self = this
      const allAudios = []
      allAudios.forEach.call(audios, function (i) {
        // 将audios中其他的audio全部暂停
        i.currentTime = 0
        i !== self && i.pause()
      })
    },
    async checkCollections (el) {
      let str = ''
      let checkArray = []
      for (const item in el) {
        str += el[item].id + ','
      }
      try {
        const { data } = await personalApi.get(`collections/daily_sentence/check?ids=${str}`)
        checkArray = data.data
      } catch (error) {
        console.error(error.response.data)
      }
      for (const item in el) {
        const idx = checkArray.indexOf((el[item].id))
        if (idx > -1) {
          el[item].collection = true
        } else {
          el[item].collection = false
        }
      }
      this.Sentences = el
    },
    doSearch () {
      this.pageIndex = 1
      this.getDailySentences(this.search)
    },
    async removeCollections (id) {
      try {
        await personalApi.delete(`collections/daily_sentence/${id}`)
        this.getDailySentences()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async addCollections (el) {
      if (this.$store.state.auth.authorized) {
        try {
          const fileData = new window.FormData()
          const json = {
            nameCn: el.local,
            audio_url: el.audio_url
          }
          fileData.append('name', el.english)
          fileData.append('json', JSON.stringify(json))
          await personalApi.post(`collections/daily_sentence/${el.id}`, fileData)
          this.getDailySentences()
          // console.log(data)
        } catch (error) {
          console.error(error.response.data)
        }
      } else {
        this.$swal({
          title: '請登入個人帳號',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .pointer {
    cursor: pointer;
  }
  .main{
    text-align: center;
  }
  .topic-selection {
    min-height: 20vh;
  }
  .topic {
    img {
      width: 100%;
    }
    h5 {
      color: rgb(1, 1, 161);
      font-weight: bold;
      text-align: left;
    }
  }
  .topic-title {
    h2 {
      color: red;
      font-weight: bold;
    }
  }
  .heart {
    margin: auto;
    img {
      width: 25px;
    }
  }
  .carousel {
    img {
      width: 100%;
    }
  }
  .daily-lists {
    td {
      text-align: left;
    }
  }
  .picture img:hover {
    box-shadow:3px 3px 5px 3px rgb(136, 136, 136);
    transform: scale(1.05) ;
    filter: contrast(1.2);
  }
  @media screen and (min-width: 800px){
    .rwd-tw{
      display: none;
    }
  }
  @media screen and (max-width: 800px){
    .tw{
      display: none;
    }
  }

</style>
