<template>
  <div class="pop-up">
    <div class="pop-box" :class="{'expand': status ==='terms'}">
      <div style="text-align: right;">
        <font-awesome-icon size="2x" @click="close" style="cursor: pointer;" :icon="['fas', 'times']" />
      </div>
      <div style="text-align: left;">
        <img src="../assets/img/元件/logo_liveabc_only_S_size.png" alt="">
      </div>
      <div v-if="status==='login'">
        <p style="text-align: left; font-weight: bolder;">外語圖書館登入</p>
        <div class="login-form">
          <form style="margin-top:5vh">
            <div class="group">
              <input v-model="auth.email" class="input-box" type="email" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入電子信箱..</label>
            </div>
            <div class="group">
              <input v-model="auth.password" class="input-box" type="password" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入密碼...</label>
            </div>
            <button type="button" style="width:100%" class="btn btn-warning" @click="login">登入</button>
            <button type="button" style="width:100%" @click="next" class="btn btn-outline-warning mt-2">我想註冊新帳號</button>
            <div style="text-align:right;">
              <a href="javascript:void(0)" @click="status='forgot'">忘記密碼</a>
            </div>
          </form>
        </div>
      </div>
      <div v-if="status==='forgot'">
        <p style="text-align: left; font-weight: bolder;">忘記密碼</p>
        <div class="login-form">
          <form style="margin-top:5vh">
            <div class="group">
              <input v-model="auth.email" class="input-box" type="email" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入電子信箱..</label>
            </div>
            <button type="button" style="width:100%" class="btn btn-warning" @click="forgotPassword">發送新密碼</button>
            <button type="button" style="width:100%" @click="back" class="btn btn-outline-warning mt-2">返回上一頁</button>
          </form>
        </div>
      </div>
      <div v-if="status==='update'">
        <p style="text-align: left; font-weight: bolder;">修改密碼</p>
        <div class="login-form">
          <form style="margin-top:5vh">
            <div class="group">
              <input v-model="auth.password" class="input-box" type="password" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入舊密碼...</label>
            </div>
              <div class="group">
              <input v-model="newPassword" class="input-box" type="password" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入新密碼...</label>
            </div>
            <button type="button" style="width:100%" @click="updatePassword" class="btn btn-warning">確認</button>
            <button type="button" style="width:100%" @click="close" class="btn btn-outline-warning mt-2">取消</button>
          </form>
        </div>
      </div>
      <div v-if="status==='register'">
        <p style="text-align: left; font-weight: bolder;">外語圖書館註冊</p>
        <div class="login-form">
          <form style="margin-top:5vh">
            <div class="group">
              <input v-model="auth.name" class="input-box" type="text" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入名字..</label>
            </div>
            <div class="group">
              <input v-model="auth.email" class="input-box" type="email" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入電子信箱..</label>
            </div>
            <div class="group">
              <input v-model="auth.password" class="input-box" type="password" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請輸入密碼...(最少8碼)</label>
            </div>
            <button type="button" style="width:100%" :disabled="!auth.terms||auth.password.length<8" class="btn btn-warning" @click="register">註冊</button>
            <button type="button" style="width:100%" @click="back" class="btn btn-outline-warning mt-2">返回上一頁</button>
            <div class="form-group form-check mt-2">
              <input v-model="auth.terms" type="checkbox" class="form-check-input" id="exampleCheck1">
              <span>我同意<span class="pointer" @click="status = 'terms'" style="color:blue">使用者隱私條款</span></span>
            </div>
          </form>
        </div>
      </div>
      <div v-if="status==='verify'">
        <p style="text-align: left; font-weight: bolder;">外語圖書館驗證</p>
        <div class="login-form">
          <form style="margin-top:5vh;">
            <div class="group">
              <input v-model="auth.verify" class="input-box" type="text" required>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>請至註冊信箱取得驗證碼</label>
            </div>
            <div class="mt-5">
              <button type="button" style="width:100%" @click="sendVerifyCode" class="btn btn-outline-warning">寄送驗證碼</button>
              <button type="button" style="width:100%" class="btn btn-warning mt-2" @click="verifyAccount">驗證</button>
            </div>
          </form>
        </div>
      </div>
      <div v-if="status==='terms'">
        <p style="text-align: left; font-weight: bolder;">使用者隱私條款</p>
        <div class="terms">
          「希伯崙股份有限公司」所屬網站(LiveABC外語學習圖書館)會員服務條款 <br>
          一、認知與接受條款 <br>
          1. 「希伯崙股份有限公司」(以下稱本公司)所經營之網站「LiveABC外語學習圖書館」網站(以下稱LiveABC外語學習圖書館)，將依據本會員服務條款（以下稱本服務）提供LiveABC外語學習圖書館各項服務內容。當您完成LiveABC外語學習圖書館會員註冊手續、或開始使用本服務時，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容。本公司有權於任何時間修改或變更本服務條款之內容，修改後的服務條款內容將公佈於LiveABC外語學習圖書館上，建議您隨時注意本服務之修改或變更內容。如您於任何修改或變更後繼續使用本服務者，視為您已閱讀、瞭解並同意接受該等修改或變更。若您不同意上述的服務條款修訂或更新方式，或您所屬的國家或地域排除本會員服務條款內容之全部或一部份，或不接受本服務條款的任一約定時，您可立即停止使用本服務。<br>
          2. 若您為未滿二十歲，則應請您的家長（或監護人）閱讀、瞭解並同意本會員服務條款之所有內容及其後修改變更後，方得使用或繼續使用本服務。當您使用或繼續使用本服務時，即表示您的家長（或監護人）已閱讀、瞭解並同意接受本會員服務條款之所有內容及其後修改變更。<br>
          3. 您同意於下列情形發生時，得依法公開您的個人資訊：<br>
          法律明文規定。<br>
          為維護國家安全或增進公共利益所必要。<br>
          為免除當事人之生命、身體、自由或財產上之危險。<br>
          為防止他人權益之重大危害。<br>
          4. 您得自由選擇提供之個人資料，若您提供之資料不正確、不完整或未隨時更正或補充，可能會無法收到刊物、優惠活動訊息等。<br>
          5. 會員有權對個人資料行使以下權利，包含查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除，如欲行使上述權利，可與本公司客服連絡進行申請。<br>
          二、會員遵守（注意）事項<br>
          您註冊為會員或於使用本服務之同時，遵守以下事項：<br>
          於本服務註冊表詢問事項，提供您本人正確、最新及完整的資料，且不得以第三人名義註冊為會員。每位會員僅能註冊及登錄一個帳號，不可重覆註冊及登錄。<br>
          為確保會員個人資料為正確、最新及完整，會員應維持並即時更新個人資料。<br>
          若會員有提供任何錯誤或不實資料、或未按指示提供資料、或欠缺必要資料、或有重覆註冊帳號等情事時，本公司有權暫停或終止會員所屬帳號之使用權限，並得拒絕會員繼續使用本服務之全部或一部。<br>
          會員須妥善保存自己的帳號及密碼，不得將密碼告訴第三者，甚或提供第三者使用。<br>
          會員的帳號、密碼及會員權益均僅供會員個人使用及享有，在任何情形下，不得以任何形式轉借、轉讓本服務予他人或與他人合用。<br>
          會員的密碼或帳號遭到盜用或有其他任何安全問題發生時，應立即通知本公司<br>

          三、隱私權聲明<br>
          會員資料依本公司「隱私權聲明」受到保護及規範，請參閱隱私權政策<br>

          四、免責聲明<br>
          有關本公司所提供之各項會員或使用者服務，均以該服務當時之功能及現況提供使用，對於會員或使用者之特定需求，包括但不限於速度、安全性、可靠性、完整性、正確性及不會斷線和出錯等，本公司皆不負擔任何明示或默示之擔保責任。<br>

          五、服務中斷或故障<br>
          本服務有時可能會出現中斷或故障等現象，或是服務設備進行必要之保養及施工時需暫時停止服務，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形。您於使用本服務時宜自行採取防護措施。本公司對於您因使用（或無法使用）本服務而造成的損害，不負任何賠償責任。<br>

          六、會員使用限制規範<br>
          您同意不將「本服務」作以下用途，否則本公司有權刪除資料或取消會員資格且不作任何賠償：<br>

          干擾或破壞「本服務」或與「本服務」相連線之伺服器和網路，或不遵守於本服務連線網路之規定或規範。<br>
          公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上。<br>
          侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。<br>
          違反依法律或契約所應負之保密義務。<br>
          冒用他人名義使用本服務。<br>
          傳輸或散佈電腦病毒。<br>
          從事不法或未經本公司授權之商業或廣告宣傳行為。<br>
          其他本公司認為不適當之行為。<br>
          您了解一切的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料，無論其為公開張貼或私下傳送，均為提供者之責任。<br>
          七、智慧財產權保護<br>
          本服務所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，除明確為法律所允許者外，必須依法取得本公司或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償責任，包括但不限於訴訟費用及律師費用等。<br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import personalApi from '../store/personalApi'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Pop-up',
  data () {
    return {
      status: 'login',
      auth: {
        name: '',
        email: '',
        password: '',
        verify: '',
        terms: false
      },
      newPassword: ''
    }
  },
  props: {
    inputStatus: {
      type: String,
      required: false,
      default: 'login'
    }
  },
  computed: {
    ...mapGetters([
      'verify'
    ])
  },
  mounted () {
    this.status = this.inputStatus
  },
  methods: {
    ...mapActions({
      dologin: 'login',
      setMe: 'setPersonalInfo'
    }),
    async register () {
      const vm = this
      try {
        await personalApi.post('register', this.auth)
        vm.$swal({
          title: '請完成接下來的驗證',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        }).then(() => {
          vm.login()
          vm.status = 'verify'
        })
      } catch (error) {
        vm.$swal({
          title: '註冊失敗',
          text: error.response.data.data.email,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    },
    close () {
      if (this.status !== 'terms') {
        this.$emit('closePopUp', false)
      } else {
        this.status = 'register'
      }
    },
    // async fristLogin () {
    //   await this.dologin({ email: this.auth.email, password: this.auth.password })
    //   await this.setMe()
    // },
    async login () {
      try {
        await this.dologin({ email: this.auth.email, password: this.auth.password })
        await this.setMe()
        if (!this.verify) {
          this.status = 'verify'
        } else {
          this.$emit('closePopUp', false)
        }
        // this.$swal({
        //   type: 'success',
        //   title: '您已登入',
        //   confirmButtonText: '確定'
        // }).then(() => {
        //   if (!vm.verify) {
        //     this.status = 'verify'
        //   } else {
        //     vm.$emit('closePopUp', false)
        //   }
        // })
      } catch (error) {
        if (error) {
          console.log(error)
          this.$swal({
            title: '登入失敗',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
        }
        // console.error(error.response.data)
      }
    },
    next () {
      this.status = 'register'
      this.auth.email = ''
      this.auth.password = ''
      this.auth.name = ''
      this.auth.verify = ''
    },
    back () {
      this.status = 'login'
      this.auth.email = ''
      this.auth.password = ''
      this.auth.name = ''
      this.auth.verify = ''
    },
    async forgotPassword () {
      const vm = this
      try {
        const fileData = new window.FormData()
        fileData.append('email', this.auth.email)
        await personalApi.post('forgot_password', fileData)
        this.$swal({
          title: '已發送新的密碼至信箱',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        }).then(() => {
          vm.auth.email = ''
          vm.back()
        })
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async updatePassword () {
      const vm = this
      try {
        const data = {
          old_password: this.auth.password,
          new_password: this.newPassword
        }
        await personalApi.post('update_password', data)
        this.$swal({
          title: '修改成功',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        vm.close()
      } catch (error) {
        this.$swal({
          title: error.response.data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        console.error(error.response.data)
      }
    },
    async sendVerifyCode () {
      try {
        await personalApi.post('verify/email/resend')
        this.$swal({
          title: '已發出驗證信',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async verifyAccount () {
      const vm = this
      try {
        const fileData = new window.FormData()
        fileData.append('code', this.auth.verify)
        await personalApi.post('verify/email', fileData)
        vm.$swal({
          title: '信箱驗證成功',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        }).then(() => {
          vm.back()
        })
      } catch (error) {
        this.$swal({
          title: error.response.data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        console.error(error.response.data)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/custom.scss";
  .pop-up {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color:rgb(0, 0, 0, .5);
    z-index: 1000;
    .pop-box {
      // transition:0.2s ease all;
      position: relative;
      // width: 50%;
      border-radius: 20px;
      padding: 30px;
      max-width: 400px;
      height: 60vh;
      min-height: 500px;
      background-color: #fff;
      margin: auto;
      margin-top: 10vh;
      z-index: 10000 !important;
    }
  }
  .expand {
    transition:0.2s ease all;
    max-width: 800px !important;
    height: 650px !important;
  }
  .group {
    position:relative;
    margin-bottom:20px;
  }
  .input-box {
    font-size:18px;
    padding:10px 10px 10px 5px;
    display:block;
    width:100%;
    border:none;
    border-bottom:1px solid #757575;
  }
  input:focus { outline:none; }

  label {
    color:#999;
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }
  input:focus ~ label, input:valid ~ label {
    top:-20px;
    font-size:14px;
    color:#000000;
  }
  .bar {
    position:relative;
    display:block;
    width:100%;
  }
  .bar:before, .bar:after {
    content:'';
    height:2px;
    width:0;
    bottom:1px;
    position:absolute;
    background:#000000;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }
  .terms {
    text-align: left;
    padding: 2px;
    max-height: 45vh;
    overflow-y: auto;
  }
  .bar:before {
    left:50%;
  }
  .bar:after {
    right:50%;
  }
  input:focus ~ .bar:before, input:focus ~ .bar:after {
    width:50%;
  }
  .login-form {
    // padding: 20px;
  }
  @media (max-height: 680px) {
    .pop-box {
      height: 400px !important;
      .terms {
        max-height: 55vh;
      }
    }
  }
</style>
