<template>
  <div>
    <div class="collection">
      <div>
        <div class="personal-box">
          <ul class="d-flex">
            <li @click="changeType" class="pointer" :class="{ 'active': type==='lesson'}" data-type="lesson">收藏課程</li>
            <li @click="changeType" class="pointer mx-4" :class="{ 'active': type==='vocabulary'}" data-type="vocabulary">我的單字</li>
            <li @click="changeType" class="pointer" :class="{ 'active': type==='sentence'}" data-type="sentence">每日一句</li>
          </ul>
        </div>
      </div>
      <div >
        <h6>以下是您的收藏</h6>
        <div v-if="type==='lesson'&& collections">
          <div class="d-flex align-items-stretch my-5" v-for="(item, index) in collections" :key="index">
            <div class="m-auto pr-3">
            </div>
            <div class="flex-grow-1">
              <b><p v-html="forceToJap(item.json.enName)"></p></b>
              <p class="m-0">{{item.json.localName}}</p>
            </div>
            <div class="my-auto mx-3">
              <font-awesome-icon size="lg" @click="deleteCollections(apiType, item.data.view_id)" style="cursor: pointer;" :icon="['fas', 'trash-alt']" />
            </div>
            <div class="m-auto">
              <button @click="goPage(item.json)" type="button" class="btn btn-primary">前往課程</button>
            </div>
          </div>
        </div>
        <div v-if="type==='vocabulary' && collections">
            <div class="d-flex justify-content-between my-3" v-for="(item, index) in collections" :key="index">
              <div>
                <div class="title pl-3" v-if="item.json.vocabulary" >
                    <span @click="playAudio(item.id, 'vocabulary')"><i style="cursor: pointer;" class="fa fa-volume-up"></i> </span>
                    <span class="ml-2" v-html="item.json.vocabulary.name"></span>
                  <div v-if="item.json.vocabulary.audio">
                    <audio :id="`vocabulary${item.id}`">
                      <source :src="item.json.vocabulary.audio" type="audio/mp3">
                    </audio>
                  </div>
                </div>
                <div class="pl-3" v-if="item.json.vocabulary">
                  <span class="pron">{{ item.json.vocabulary.pronouncing }}</span><br />
                  {{ item.json.vocabulary.partOfSpeech }} {{ item.json.vocabulary.name_i18N[0].value }}<br />

                  <p v-for="(example, exampleIndex) in item.json.vocabulary.examples" :key="exampleIndex"
                    v-bind:class="{ 'clickable': example.audio }"
                    >
                    <span class="mr-2" style="cursor: pointer;" @click="playAudio(index, 'examples')" v-if="example.audio"><i class="fa fa-volume-up"></i></span> <span v-html="example.text"></span><br />
                    <span v-for="(lang, langIndex) in example.text_i18N" :key="langIndex">{{ lang.value }}</span>
                    <audio :id="`examples${index}`">
                      <source  :src="example.audio" type="audio/mp3">
                    </audio>
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="my-auto mx-3">
                  <font-awesome-icon size="lg" @click="deleteCollections(apiType, item.data.view_id, item)" style="cursor: pointer;" :icon="['fas', 'trash-alt']" />
                </div>
                <div class="m-auto">
                  <button @click="goPage(item.json)" type="button" class="btn btn-primary">前往課程</button>
                </div>
              </div>
            </div>
        </div>
        <div v-if="type==='sentence'&& collections">
          <div class="d-flex align-items-stretch my-5"  v-for="(item, index) in collections" :key="index">
            <div class="m-auto pr-3">
              <!-- {{index}} -->
            </div>
            <div class="flex-grow-1">
              <!-- <p class="m-0"><b>{{item.name}}</b></p> -->
              <b><p v-html="forceToJap(item.name)"></p></b>
              <p>
                {{item.json.nameCn}}
              </p>
            </div>
            <div class="m-auto pr-3">
              <div v-if="item.json.audio_url" class="mr-2">
                <font-awesome-icon size="lg" @click="audioPasue(item, 'daily-sentences')" class="pointer"  v-if="isPlay === item.id" :icon="['far', 'pause-circle']" />
                <font-awesome-icon size="lg" @click="playAudio(item.id, 'daily-sentences')" class="pointer" v-else :icon="['far', 'play-circle']" />
              </div>
            </div>
            <div v-if="item.json.audio_url">
              <audio :id="`daily-sentences${item.id}`">
                <source :src="item.json.audio_url" type="audio/mp3">
              </audio>
            </div>
            <div class="my-auto mx-3">
              <font-awesome-icon size="lg" @click="deleteCollections(apiType, item.data.id)" style="cursor: pointer;" :icon="['fas', 'trash-alt']" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination v-if="collections" :current="pageIndex" :total="this.pageCount" v-on:page="toPage"/>
  </div>
</template>
<script>
// @ is an alias to /src
// import PopUp from '@/components/Pop-up.vue'
import pagination from '../components/Pagination'
import personalApi from '../store/personalApi'
export default {
  name: 'Collections',
  components: {
    // PopUp
    pagination
  },
  data () {
    return {
      type: 'lesson',
      collections: null,
      formatData: null,
      isPlay: null,
      pageIndex: 1,
      pageCount: 0
    }
  },
  computed: {
    apiType () {
      switch (this.type) {
        case 'lesson':
          return 'live_course'
        case 'vocabulary':
          return 'word'
        case 'sentence':
          return 'daily_sentence'
      }
      return null
    }
  },
  watch: {
    apiType (val) {
      this.collections = null
      this.getCollections(val)
    }
  },
  mounted () {
    const subTitle = [
      {
        name: '首頁',
        routeName: 'Home'
      },
      {
        name: '個人服務',
        routeName: 'Personal'
      }
    ]
    this.getCollections(this.apiType)
    this.$store.commit('setSubTitle', subTitle)
  },
  methods: {
    changeType (val) {
      this.type = val.target.dataset.type
    },
    async getCollections (type) {
      try {
        const { data } = await personalApi.get(`collections/${type}?page=${this.pageIndex}&pageSize=10`)
        this.collections = data.data
        this.pageCount = data.pageCount
      } catch (error) {
        console.error(error.response.data)
      }
    },
    deleteCollections (type, id, el) {
      const self = this
      self.$swal({
        title: '確定要刪除?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        closeOnConfirm: false
      }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          self.removeCollections(type, id, el)
        }
      })
    },
    async removeCollections (type, id, el) {
      if (type === 'word') {
        try {
          await personalApi.delete(`collections/${type}/${id}`, { data: { word: el.name } })
          this.getCollections(this.apiType)
        } catch (error) {
          console.error(error.response.data)
        }
      } else {
        try {
          await personalApi.delete(`collections/${type}/${id}`)
          this.getCollections(this.apiType)
          // console.log(data)
        } catch (error) {
          console.error(error.response.data)
        }
      }
    },
    playAudio (id, type) {
      console.log(id)
      this.isPlay = id
      this.playSentences(id, type)
    },
    playSentences (id, type) {
      // console.log(`${type}${id}`)
      const audio = document.getElementById(`${type}${id}`)
      const audioPromise = document.getElementById(`${type}${id}`).play()
      this.pauseAll()
      audio.load()
      if (audioPromise !== undefined) {
        audioPromise.then(_ => {
          audio.play()
        })
      }
      audio.play()
    },
    audioPasue (el, type) {
      this.isPlay = ''
      const audio = document.getElementById(`${type}${el.id}`)
      audio.pause()
    },
    pauseAll () {
      const audios = document.getElementsByTagName('audio')
      const self = this
      const allAudios = []
      allAudios.forEach.call(audios, function (i) {
        i.currentTime = 0
        i !== self && i.pause()
      })
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    },
    goPage (el) {
      // console.log(el)
      this.$router.push({ name: el.name || 'Lesson-player', params: el.params })
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getCollections(this.apiType)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .collection {
    min-height: 100vh;
  }
  .personal-box {
      width: 100%;
      margin: 5px auto;
      padding: 20px 0 20px 0 ;
      border-bottom: 1px solid #000;
      ul {
        list-style: none;
        li{
          font-weight: bolder;
          margin: 0 10px 0 0;
          color: rgba(0, 0, 255, 0.438);
          p{
            padding: 0;
          }
        }
      }
    }
    .active {
      color: blue !important;
      border-bottom: 2px solid blue;
    }
</style>
