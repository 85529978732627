<template>
  <div id="app">
    <loading :active.sync="isLoading"></loading>
    <Navbar v-on:emitSearch="getSearch" v-if="this.routeName !=='Home'"/>
    <div class="main">
      <router-view :inputSearch="searchData" :key="$route.fullPath" />
    </div>
    <Footer v-if="this.$route.name !== 'Home'"/>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Loading
  },
  data () {
    return {
      searchData: ''
    }
  },
  watch: {
    errorMessage (val) {
      this.setErrorMessage(val)
    }
  },
  mounted () {
  },
  methods: {
    getSearch (value) {
      this.searchData = value
    },
    setErrorMessage (val) {
      const self = this
      if (val.indexOf('Access denied') > -1) {
        this.$swal({
          title: '您目前無法啟用外語學習圖書館服務。',
          html: '<br><span>1.請檢查是否已登入帳號。</span><br><span>2.您所在的學校(單位)未購買使用授權或您不在學校(單位)的授權IP範圍。請向您所在的學校(單位)洽詢或申請註冊14天試用帳號。</span><br><span>3.您註冊的14天試用帳號已到期。請洽校園服務專線。</span><br><br><span><br><br><span>台北 (02)2660-0608 新竹 (03)562-6866 <br>台中 (04)2249-1588 高雄 (07)236-0227</span></ul>',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        self.$router.push({ name: 'Home' })
        self.$store.commit('setErrorMessage', '')
      } else if (val.indexOf('Too Many Attempts.') > -1) {
        this.$swal({
          title: '流量已超過限制',
          html: '<span>目前系統使用人數已達購買方案之最高上限。 <br> 請稍後再試或洽詢學校資訊單位。</span>',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        self.$router.push({ name: 'Home' })
        self.$store.commit('setErrorMessage', '')
      }
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'errorMessage'
    ]),
    routeName () {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: Microsoft JhengHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex; /*使物件依序排列*/
  flex-direction: column; /*使物件垂直排列*/
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
  .main {
    flex-grow: 1;
    height: calc(100vh - 70px) !important ;
  }
}
</style>
