<template>
  <div style="min-height: calc(100vh);">
    <div class="main-info" style="margin-top:120px">
      <div class="container-fluid">
        <div v-if="Info" class="row ">
          <div class="col-md-5 align-self-center">
            <img v-if="topic === 'mag'" style="min-width:200px" :src="Info.cover?Info.cover:Info[selectedMag.cate-1].verticalCover" alt="pic_info">
            <img v-if="topic === 'taiwan'" style="min-width:200px" :src="Info.cover?Info.cover:Info.horizontalCover" alt="pic_info">
            <div class="mt-2" style="color:#fff;font-size: 1.2rem;">
              單元數:{{lessonCount}}
            </div>
          </div>
          <div class="col-md-7 Info" style="padding: 5% 10%">
            <h2>{{Info.name ? Info.name : Info[selectedMag.cate-1].name}}</h2>
            <p>
              {{Info.description||Info.description===null ? Info.description:Info[selectedMag.cate-1].description}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="padding:5vh 5vw">
      <div v-if="topic === 'mag'" class="row justify-content-around mg-select">
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedMag.year" name="" id="">
            <option disabled selected hidden value="">依年份排列</option>
            <option value="">ALL</option>
            <option v-for="(item, key) in year" :value="item" :key="key">{{item}}年</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" :disabled="!selectedMag.year" v-model="selectedMag.month" name="" id="">
            <option disabled selected hidden value="">依月份排列</option>
            <option value="">ALL</option>
            <option v-for="item in month" :value="item" :key="item">{{item}}月</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedMag.cate" name="" id="">
            <option disabled selected hidden value="">依主分類排列</option>
            <option v-if="$route.name === 'Info'" value="">ALL</option>
            <option v-for="(item, key) in cate" :value="item.id" :key="key">{{item.name}}</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" :disabled="!selectedMag.cate" v-model="selectedMag.sub" name="" id="">
            <option disabled selected hidden value="">依子分類排列</option>
            <option value="">ALL</option>
            <option  v-for="(item, key) in subCate" :value="item.id" :key="key">{{item.name}}</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedMag.type" name="" id="">
            <option disabled selected hidden value="">依類型排列</option>
            <option value="">ALL</option>
            <option value="Conversation">影片課程</option>
            <option value="Article">文章課程</option>
            <option value="Writing">寫作課程</option>
            <option value="IllustratedDictionary">圖解課程</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedMag.lang" name="" id="">
            <option disabled selected hidden value="">依語言排列</option>
            <option value="">ALL</option>
            <option value="En">英文</option>
            <option value="Ja">日文</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedMag.level" name="" id="">
            <option disabled selected hidden value="">依等級排列</option>
            <option value="">ALL</option>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="B1">B1</option>
            <option value="B2">B2</option>
            <option value="C1">C1</option>
            <option value="C2">C2</option>
          </select>
        </div>
      </div>
      <div v-if="topic === 'taiwan'" class="row justify-content-around mg-select">
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedTw.cate" name="" id="">
            <option disabled selected hidden value="">依主分類排列</option>
            <option v-if="$route.name === 'Info'" value="">ALL</option>
            <option v-for="(item, key) in cate" :value="item.id" :key="key">{{item.name}}</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" :disabled="!selectedTw.cate" v-model="selectedTw.sub" name="" id="">
            <option disabled selected hidden value="">依子分類排列</option>
            <option value="">ALL</option>
            <option  v-for="(item, key) in subCate" :value="item.id" :key="key">{{item.name}}</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedTw.type" name="" id="">
            <option disabled selected hidden value="">依類型排列</option>
            <option value="">ALL</option>
            <option value="Conversation">影片課程</option>
            <option value="Article">文章課程</option>
            <option value="Writing">寫作課程</option>
            <option value="IllustratedDictionary">圖解課程</option>
          </select>
        </div>
        <div class="col-md my-2">
          <select style="width:90%" v-model="selectedTw.level" name="" id="">
            <option disabled selected hidden value="">依等級排列</option>
            <option value="">ALL</option>
            <!-- <option value="A1">A1</option> -->
            <option value="A2">A2</option>
            <option value="B1">B1</option>
            <option value="B2">B2</option>
            <option value="B2">C1</option>
            <!-- <option value="C2">C2</option> -->
          </select>
        </div>
      </div>
      <div v-if="lessons">
        <div v-if="lessons.length> 0" class="row justify-content-start mb-5">
          <div class="col-12 topic-title">
            <h2>
              數位課程
            </h2>
            <a style="text-decoration:none; cursor: default;" href="javascript:void(0)">
              請點選下方分類封面進入
            </a>
          </div>
          <div v-for="(item,key) in lessons" :key="key" class="col-lg-3 col-md-4 col-sm-6">
            <div class="topic mb-3 card" >
              <div class="picture"  @click="goLesson(item)">
                <img class="card-img" :src="item.cover" :alt="'pic_'+key">
              </div>
              <div class="card-body">
                <div style="height: 10rem">
                  <p style=" font-weight: bold; font-size: 1.25rem" class="card-text">{{item.nameCht}}</p>
                  <p v-html="forceToJap(item.nameEn)" class="card-text sub-text">{{item.nameEn}}</p>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <div v-if="topic==='mag'">
                      Date: {{item.year}}.{{item.month}}
                    </div>
                  </div>
                  <div v-if="item.level!== null">
                    Level: {{item.level}}
                  </div>
                  <div class="heart">
                    <img class="pointer" @click.self="removeCollections(item.lessonId)" v-if="item.collection" src="../assets/red.png" alt="">
                    <img class="pointer" @click.self="addCollections(item)"  v-else src="../assets/heart.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <pagination class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
          </div>
        </div>
        <div v-else class="col-12 align-items-center" style=" height: 30vh;margin-top:20%">
          <h4><b>沒有符合條件的課程</b></h4>
        </div>
      </div>
    </div>
    <!-- <Player/> -->
  </div>
</template>
<script>
// @ is an alias to /src
import personalApi from '../store/personalApi'
import pagination from '../components/Pagination'
import api from '@/store/api'
export default {
  name: 'Info',
  components: {
    pagination
  },
  props: {
    inputSearch: {
      type: String
    }
  },
  data () {
    return {
      Info: null,
      cate: [],
      lessons: null,
      lessonCount: 0,
      selectedMag: {
        year: '',
        month: '',
        cate: '',
        sub: '',
        type: '',
        level: '',
        lang: ''
      },
      selectedTw: {
        cate: '',
        sub: '',
        type: '',
        level: '',
        lang: ''
      },
      subCate: null,
      month: null,
      pageIndex: 1,
      pageCount: 0
    }
  },
  computed: {
    topic () {
      return this.$route.params.topic
    },
    infoId () {
      return this.$route.params.infoId
    },
    year () {
      const d = new Date()
      let od = 2011
      const years = []
      while (od < d.getFullYear()) {
        od++
        years.push(od)
      }
      return years
    }
  },
  updated () {
  },
  async mounted () {
    if (this.$route.name === 'Theme-info') {
      this.selectedMag.cate = this.$route.params.infoId
      this.selectedTw.cate = this.$route.params.infoId
    }
    await this.getInfo()
    await this.getCate()
    const subTitle = [
      {
        name: '首頁',
        routeName: 'Home'
      },
      {
        name: this.topic === 'mag' ? '雜誌館' : '遊台灣',
        routeName: 'Topic',
        params: this.$route.params
      },
      {
        name: this.Info.name ? this.Info.name : this.Info[this.selectedMag.cate - 1].name
      }
    ]
    this.$store.commit('setSubTitle', subTitle)
  },
  watch: {
    selectedMag: {
      handler (val) {
        if (this.topic === 'mag') {
          this.getMagLessons()
          const d = new Date()
          this.month = val.year < d.getFullYear() ? 12 : d.getMonth() + 1
        }
      },
      immediate: true,
      deep: true
    },
    selectedTw: {
      handler (val) {
        if (this.topic === 'taiwan') {
          this.getTaiwanLessons()
        }
      },
      immediate: true,
      deep: true
    },
    'selectedMag.sub' (val) {
      this.getMagLessons(val)
    },
    'selectedTw.sub' (val) {
      this.getTaiwanLessons(val)
    },
    'selectedMag.cate' (val) {
      this.getSubCate(val)
      this.selectedMag.sub = ''
      const subTitle = [
        {
          name: '首頁',
          routeName: 'Home'
        },
        {
          name: this.topic === 'mag' ? '雜誌館' : '遊台灣',
          routeName: 'Topic',
          params: this.$route.params
        },
        {
          name: this.Info[val - 1].name
        }
      ]
      this.$store.commit('setSubTitle', subTitle)
      this.pageIndex = 1
    },
    'selectedTw.cate' (val) {
      this.getInfo()
      this.getSubCate(val)
      this.selectedTw.sub = ''
      const subTitle = [
        {
          name: '首頁',
          routeName: 'Home'
        },
        {
          name: this.topic === 'mag' ? '雜誌館' : '遊台灣',
          routeName: 'Topic',
          params: this.$route.params
        },
        {
          name: this.Info[val - 1].name
        }
      ]
      this.$store.commit('setSubTitle', subTitle)
      this.pageIndex = 1
    },
    'selectedMag.year' (val) {
      this.selectedMag.month = ''
      this.pageIndex = 1
    },
    inputSearch (val) {
      this.pageIndex = 1
      if (this.topic === 'mag') {
        this.getMagLessons()
      } else if (this.topic === 'taiwan') {
        this.getTaiwanLessons()
      }
    }
  },
  methods: {
    async getInfo () {
      if (this.$route.name === 'Info') {
        if (this.topic === 'mag') {
          const { data } = await api.get(`/Mag/${this.$route.params.infoId}`)
          if (data.status === 'OK') {
            this.Info = data.data
          }
        } else if (this.topic === 'taiwan') {
          const { data } = await api.get(`/TravelTaiwan/County/${this.$route.params.infoId}`)
          if (data.status === 'OK') {
            this.Info = data.data
            await this.getTaiwanLessons()
          }
        }
      } else if ((this.$route.name === 'Theme-info')) {
        if (this.topic === 'mag') {
          const params = {
            parentId: '',
            magId: this.$route.params.infoId
          }
          const { data } = await api.get('/Mag/Category/', { params })
          if (data.status === 'OK') {
            this.Info = data.data
            // this.getSubCate()
          }
        } else {
          const { data } = await api.get(`/TravelTaiwan/Category/${this.selectedTw.cate}`)
          if (data.status === 'OK') {
            this.Info = data.data
            // this.getSubCate()
          }
        }
      }
    },
    async getMagLessons (id) {
      const params = {
        magId: this.$route.name !== 'Theme-info' ? this.infoId : '',
        year: this.selectedMag.year,
        month: this.selectedMag.month,
        cateId: id || this.selectedMag.cate,
        lessonType: this.selectedMag.type,
        cefr: this.selectedMag.level,
        lang: this.selectedMag.lang,
        search: this.inputSearch,
        page: this.pageIndex,
        pageSize: 12
      }
      const { data } = await api.get('/Mag/Lessons', { params })
      if (data.status === 'OK') {
        if (this.$store.state.auth.authorized) {
          this.checkCollections(data.data)
        } else {
          this.lessons = data.data
        }
        this.lessonCount = data.totalCount
        this.pageCount = data.pageCount
      }
    },
    async getTaiwanLessons (id) {
      const params = {
        countyId: this.$route.name !== 'Theme-info' ? this.infoId : '',
        cateId: id || this.selectedTw.cate,
        type: this.selectedTw.type,
        level: this.selectedTw.level,
        query: this.inputSearch,
        page: this.pageIndex,
        pageSize: 12
      }
      const { data } = await api.get('/TravelTaiwan/Lessons', { params })
      if (data.status === 'OK') {
        if (this.$store.state.auth.authorized) {
          this.checkCollections(data.data)
        } else {
          this.lessons = data.data
        }
        this.lessonCount = data.totalCount
        this.pageCount = data.pageCount
      }
    },
    async getCate () {
      if (this.topic === 'mag') {
        const { data } = await api.get('/Mag/Category')
        if (data.status === 'OK') {
          this.cate = data.data
        }
      } else {
        const { data } = await api.get('/TravelTaiwan/Category')
        if (data.status === 'OK') {
          this.cate = data.data
        }
      }
    },
    goLesson (el) {
      console.log(el)
      this.recordsActions(el.lessonId, el.nameEn)
      const route = this.$router.resolve({ name: 'Lesson-player', params: { topic: this.topic, infoId: this.infoId, lessonId: el.lessonId } })
      window.open(route.href, '_blank')
    },
    async recordsActions (id, name) {
      const fileData = new window.FormData()
      fileData.append('id', id)
      fileData.append('type', 'view_course')
      fileData.append('name', name)
      await personalApi.post('/records/actions', fileData)
    },
    async getSubCate (id) {
      const params = {
        parentId: id,
        magId: this.$route.name === 'Info' ? this.$route.params.infoId : ''
      }
      if (this.topic === 'mag') {
        const { data } = await api.get('/Mag/Category/', { params })
        if (data.status === 'OK') {
          this.subCate = data.data
        }
      } else {
        const { data } = await api.get('/TravelTaiwan/Category/', { params })
        if (data.status === 'OK') {
          this.subCate = data.data
        }
      }
    },
    toPage (toPage) {
      this.pageIndex = toPage
      if (this.topic === 'mag') {
        this.getMagLessons()
      } else {
        this.getTaiwanLessons()
      }
    },
    async addCollections (el) {
      if (this.$store.state.auth.authorized) {
        const json = {
          name: 'Lesson-player',
          localName: el.nameCht,
          enName: el.nameEn,
          params: {
            infoId: this.$route.params.infoId,
            topic: this.$route.params.topic,
            lessonId: el.lessonId
          }
        }
        // console.log(json)
        const fileData = new window.FormData()
        // fileData.append('name', el.nameEn)
        fileData.append('json', JSON.stringify(json))
        fileData.append('view_id', el.lessonId)
        await personalApi.post('/collections/live_course', fileData)
        if (this.topic === 'mag') {
          this.getMagLessons()
        } else {
          this.getTaiwanLessons()
        }
      } else {
        this.$swal({
          title: '請登入個人帳號',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    },
    async checkCollections (el) {
      let str = ''
      let checkArray = []
      for (const item in el) {
        str += el[item].lessonId + ','
      }
      try {
        const { data } = await personalApi.get(`collections/live_course/check?page=1&pageSize=10&ids=${str}`)
        checkArray = data.data
      } catch (error) {
        console.error(error.response.data)
      }
      for (const item in el) {
        const idx = checkArray.indexOf((el[item].lessonId))
        if (idx > -1) {
          el[item].collection = true
        } else {
          el[item].collection = false
        }
      }
      this.lessons = el
    },
    async removeCollections (id) {
      try {
        await personalApi.delete(`/collections/live_course/${id}`)
        if (this.topic === 'mag') {
          this.getMagLessons()
        } else {
          this.getTaiwanLessons()
        }
      } catch (error) {
        console.error(error.response.data)
      }
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .main{
    text-align: center;
  }
  .topic-selection {
    min-height: 20vh;
  }
  .topic {
    img {
      width: 100%;
    }
    h5 {
      color: blue;
      font-weight: bold;
      text-align: left;
    }
  }
  .topic-title {
    padding: 20px;
    h2 {
      color: red;
      font-weight: bold;
    }
  }
  .main-info {
    background-color: rgb(132, 132, 177);
    padding: 20px 0;
    img {
      width: 30%;
    }
    .Info {
      text-align: left;
      color: #fff;
      font-size: 24px;
    }
  }
  .picture {
    position:relative;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    img {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition:all 0.2s ease;
    }
  }
  .card {
    .heart {
      width: 25px;
    }
  }
  .card:hover {
    box-shadow:3px 3px 5px 3px rgb(136, 136, 136);
    transform: scale(1.05);
    filter: contrast(1.2);
  }
</style>
