<template>
  <div class="fixed-top" style="z-index:501">
    <pop-up v-if="toggle" v-on:closePopUp="popUpToggle">
    </pop-up>
    <nav class="navbar navbar-expand-lg navbar-light bg-light p-3 d-flex">
      <div class="flex-grow-1" style="text-align:left">
        <img class="title-img" style="cursor: pointer; width:350px" @click="$router.push({name:'Home'})" src="../assets/img/元件/pic_company_logo_S.png" alt="">
      </div>
      <div v-if="routeName === 'Info' || routeName ==='Theme-info'" class="search mr-3">
        <input placeholder="標題搜尋" @keyup.enter="getSearch()" v-model="search" class="search-text" type="text">
        <img style="cursor: pointer;" @click="getSearch()" class="search-icon" src="../assets/img/元件/icon_search.png" alt="">
      </div>
      <div class="d-flex" style="position: relative;">
        <span v-if="!this.$store.state.auth.authorized" style="cursor: pointer;color:#fff" @click="openPersonal">
          登入/註冊
        </span>
        <span v-else  @click="openPersonal" style="cursor: pointer;color:#fff">
          你好，{{name}}
        </span>
        <span>
        </span>
        <div class="info" @mouseover="infoActive = true" @mouseleave="infoActive = false">
          i
        </div>
        <div class="info-content" :class="{hide:!infoActive}">
          請登入個人帳號後，可使用網站中的個人使用紀錄功能及收藏功能。
        </div>
      </div>
    </nav>
    <nav class="sub-navbar navbar navbar-expand p-3" @mouseover="active = true" @mouseleave="active = false">
      <div class="d-flex justify-content-between" style="width:100%">
        <div>
          <a @click="goPage(item)" href="javascript:void(0)" v-for="(item ,key) in $store.state.subTitle" :key="key" >
            {{ item.name }} <span v-show="$store.state.subTitle.length -1 > key"> > </span>
          </a>
        </div>
        <div class="daily">
          <span v-if="audioData" @click="playSentences()">
            <img style="width:20px;cursor: pointer;" class="pointer" src="../assets/img/元件/comment-dots-regular.svg" alt="">
              <span v-if="!active">
                Sentence of the Day:{{audioData[0].english}}
              </span>
              <span v-else>
                每日一句: {{audioData[0].local}}
              </span>
          </span>
        </div>
        <span class="more ml-3" @click="$router.push({ name: 'Topic', params: { topic: 'daily' }})">
          更多
        </span>
      </div>
    </nav>

    <audio id="daily-sentences" v-if="audioData">
      <source :src="audioData[0].audio_url" type="audio/mp3">
    </audio>
  </div>
</template>

<script>
// import personalApi from '../store/personalApi'
import { mapGetters } from 'vuex'
import PopUp from '@/components/Pop-up.vue'
import axios from 'axios'
// import api from '@/store/api'
export default {
  name: 'Navbar',
  data () {
    return {
      search: '',
      audioData: null,
      active: false,
      infoActive: false,
      toggle: false,
      auth: {
        email: '',
        password: ''
      }
    }
  },
  components: {
    PopUp
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val) {
          this.$emit('emitSearch', this.search)
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getDailySentences()
    // if (this.$store.state.auth.authorized) {
    //   this.getUser()
    // }
  },
  computed: {
    ...mapGetters([
      'authorized',
      'name',
      'verify'
    ]),
    routeName () {
      return this.$route.name
    },
    topic () {
      return this.$route.params.topic
    },
    infoId () {
      return this.$route.params.infoId
    }
  },
  methods: {
    goPage (item) {
      const params = item.params
      this.search = ''
      this.$emit('emitSearch', '')
      if (item.params) {
        this.$router.push({ name: item.routeName, params })
      } else {
        this.$router.push({ name: item.routeName })
      }
    },
    async getSearch () {
      this.$emit('emitSearch', this.search)
      this.search = ''
    },
    async getDailySentences () {
      const { data } = await axios.get('https://api.liveabc.com/v1/daily-sentences')
      this.audioData = data.data.slice(0, 1)
    },
    playSentences (id) {
      const audio = document.getElementById('daily-sentences')
      const audioPromise = document.getElementById(`daily-sentences${id}`).play()
      this.pauseAll()
      if (audioPromise !== undefined) {
        audioPromise.then(_ => {
          audio.play()
        })
      }
      audio.play()
    },
    pauseAll () {
      const audios = document.getElementsByTagName('audio')
      const self = this
      const allAudios = []
      allAudios.forEach.call(audios, function (i) {
        // 将audios中其他的audio全部暂停
        i.currentTime = 0
        i !== self && i.pause()
      })
    },
    openPersonal () {
      if (this.authorized) {
        this.$router.push({ name: 'Record' })
      } else {
        this.toggle = true
      }
    },
    popUpToggle (val) {
      this.toggle = val
    },
    mouseOver () {
      this.active = !this.active
    }
    // async getUser () {
    //   const { data } = await personalApi.get('/me')
    //   console.log(data)
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .navbar {
    height: 80px;
    background-color:rgb(50, 79, 248) !important;
    padding: 0% 10% !important;
  }
  .sub-navbar  {
    background-color:rgb(184, 184, 184) !important;
    height: 40px;
  }
  .search {
    position: relative;
    .search-text {
      // width: 300px;
      border-radius: 1rem;
      border: 0;
      outline: none;
      padding:0 10px;
    }
    img {
      width: 20px;
      position: absolute;
      top: 3px;
      margin: auto;
      right: 10px;
    }
  }
  .daily {
    max-width: 50vw;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .info {
    border-radius: 50px;
    background-color: #000;
    margin: auto 5px;
    width: 16px;
    height: 16px;
    color: #fff;
    font-size: .8rem;
  }
  .info-content {
    padding: 10px;
    right: 0px;
    top: 25px;
    position: absolute;
    width: 200px;
    height: 100px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 500;
  }
  .hide {
    display: none;
  }
  .more {
    color: blue;
    cursor: pointer;
  }
  @media screen and (max-width: 1600px){
    .title-img {
      max-width: 300px !important;
    }
  @media screen and (max-width: 1000px){
    .daily {
      // color: brown;
      display: none;
    }
  }
}
</style>
