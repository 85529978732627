import Vue from 'vue'
// import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap' // Import js file
import 'bootstrap/dist/css/bootstrap.min.css' // Import css file
import VueCarousel from 'vue-carousel'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlayCircle, faPauseCircle, faHeart } from '@fortawesome/free-regular-svg-icons'
import { faTimes, faTrashAlt } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import moment from 'moment'

library.add(faPlayCircle, faPauseCircle, faTimes, faHeart, faTrashAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueAxios, axios)
Vue.use(VueCarousel)
Vue.use(VueSweetalert2)
Vue.config.productionTip = false
Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(daraStr).format(pattern)
})
Vue.prototype.moment = moment

// Vue.use(VueAnalytics, {
//   id: 'G-MXNRWY8456',
//   router,
//   autoTracking: {
//     pageviewOnLoad: false
//   }
// })
Vue.use(VueGtag, {
  config: { id: 'G-MXNRWY8456' }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
