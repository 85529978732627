import axios from 'axios'
import store from '../store'

const serverIp = 'livelibrary-api.liveabc.com/api/'
export const apiPath = 'https://' + serverIp

const api = axios.create({
  baseURL: apiPath,
  timeout: 60000,
  headers: {
    'access-control-allow-origin': '*',
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(function (config) {
  // let token = JSON.parse(window.localStorage.getItem('campus_cms')).auth.user.access_token
  // config.headers.Authorization = 'Bearer ' + token
  config.headers.Authorization = 'Bearer ' + store.state.auth.user
  if (!(config.method === 'post')) {
    store.commit('isLoading', true)
  }

  // store.commit('isLoading', true)

  return config
}, function (error) {
  return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
  store.commit('isLoading', false)
  return response
}, function (error) {
  store.commit('isLoading', false)
  if (error.response && error.response.status === 401) {
    // this.$store.dispatch('logout')
    store.commit('logout')
    this.$router.push({ name: 'Home' })
    // store.commit('setErrorMessage', 'already_logout')
    console.log(error.response)
  } else if (error.response) {
    store.commit('isLoading', false)
    console.log(error.response)
    // store.commit('setErrorMessage', error.response.data.Message)
    console.log(error.response.statusText)
  } else {
    // store.commit('setErrorMessage', error.message)
  }
  return Promise.reject(error)
})

export default api
